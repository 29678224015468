










































































import { Component, Vue } from "vue-property-decorator";
import config from "../config";
import discordSvg from "@/assets/discord-button.svg";
import redditSvg from "@/assets/reddit-button.svg";
import cnvoronSvg from "@/assets/cnvoron-button.svg";
import { BButton, BModal, BSpinner } from "bootstrap-vue";

const oauth = config.oauth;

@Component({ components: { BButton, BModal, BSpinner } })
export default class Login extends Vue {
  private providerSvgs = {
    //TODO: make dynamic?
    discord: discordSvg,
    reddit: redditSvg,
    cnvoron: cnvoronSvg
  };
  private oauth = oauth;
  private showModal = false;
  private busy: boolean | string = false;
  private error: string | false = false;
  private memberNotFound = false;

  private async login(provider: string) {
    this.busy = provider;
    const result = await this.$store.dispatch(
      "authentication/handleLogin",
      provider
    );
    if (result?.error) {
      this.error = result.error;
      if (result.error === "member not found in guild") {
        this.memberNotFound = true;
        this.busy = false;
        this.error = false;
      }
    } else {
      this.showModal = false;
      setTimeout(() => (this.busy = false), 250);
    }
  }

  created() {
    if (this.$route.path === "/login") {
      this.$store.dispatch("authentication/loginPopupCallback");
    }
  }
}
