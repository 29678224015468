/* eslint-disable @typescript-eslint/camelcase */
export default process.env.NODE_ENV === "production" //&& process.env.npm_config_stage === "prod"
  ? //PROD
    {
      oauth: {
        discord: {
          client_id: "721324422669008926",
          auth_url: "https://discord.com/oauth2/authorize",
          response_type: "code",
          scope: "identify",
          prompt: "none",
          width: 480
        },
        reddit: {
          client_id: "tJfEbIfZ09Rhjw",
          auth_url: "https://www.reddit.com/api/v1/authorize",
          response_type: "code",
          scope: "identity",
          prompt: "",
          width: 860
        },
        cnvoron: {
          client_id: "IeLeXfWF8mCZfRCYGCBMDPYBo4R9SKo6oQk0C4iV",
          auth_url: "https://www.cnvoron.com/oauth/authorize/",
          response_type: "code",
          scope: "basic",
          prompt: "",
          width: 480
        }
      }
    }
  : //DEV
    {
      oauth: {
        discord: {
          client_id: "794673338118307882",
          auth_url: "https://discord.com/oauth2/authorize",
          response_type: "code",
          scope: "identify",
          prompt: "none",
          width: 480
        },
        reddit: {
          client_id: "-IFm8PB9If3C5dsrVldBzw",
          auth_url: "https://www.reddit.com/api/v1/authorize",
          response_type: "code",
          scope: "identity",
          prompt: "",
          width: 860
        },
        cnvoron: {
          client_id: "IeLeXfWF8mCZfRCYGCBMDPYBo4R9SKo6oQk0C4iV", //TODO
          auth_url: "https://www.cnvoron.com/oauth/authorize/",
          response_type: "code",
          scope: "basic",
          prompt: "",
          width: 480
        }
      }
    };
