import Vue from "vue";
import Vuex from "vuex";
import VuexPersistence from "vuex-persist";
import { authentication, AuthState } from "./authentication.module";

Vue.use(Vuex);

const vuexLocal = new VuexPersistence({
  storage: window.localStorage,
  modules: ["authentication"]
});

export interface RootState {
  authentication?: AuthState;
}

export default new Vuex.Store<RootState>({
  strict: process.env.NODE_ENV === "development",
  modules: {
    authentication
  },
  plugins: [vuexLocal.plugin]
});
