var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('button',{class:[
    'btn btn-block',
    {
      'btn-danger': _vm.error,
      'btn-dark': _vm.editing && !_vm.error,
      'btn-success': _vm.success,
      'btn-svg btn-discord': !_vm.error && !_vm.success && !_vm.editing
    }
  ],attrs:{"id":"discord-transfer","disabled":_vm.busy},on:{"click":_vm.handleTransfer}},[(_vm.error)?_c('span',[_vm._v(_vm._s(_vm.error)+" - Try again?")]):(_vm.editing)?_c('span',[_vm._v("Confirm details and transfer")]):(_vm.success)?_c('span',[_vm._v("Success!")]):(_vm.busy)?_c('b-spinner',{staticClass:"spinner-border-sm"}):[_c('span',[_vm._v("Transfer your serial to ")]),_c('img',{attrs:{"src":_vm.discordSvg,"alt":""}})]],2)}
var staticRenderFns = []

export { render, staticRenderFns }