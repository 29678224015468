






















import { Component, Vue } from "vue-property-decorator";
import { Serial } from "@/types";
import SerialCard from "@/components/SerialCard.vue";
import registryService from "@/services/registry.service";

@Component({
  components: {
    SerialCard
  }
})
export default class User extends Vue {
  private user: { serials: Serial[] } = {
    serials: []
  };
  private userNotFound = false;

  private async loadUser() {
    this.user.serials = await registryService
      .getUserSerials(this.$route.params.userId)
      .catch(error => {
        this.userNotFound = error.response.status === 404;
        return [];
      });
    console.log("user:", this.user);
  }

  private get sortedSerials() {
    return [...this.user.serials].sort((a, b) => b.issued_time - a.issued_time);
  }

  created() {
    this.loadUser();
  }
}
