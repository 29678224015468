import Vue from "vue";
import VueRouter, { RouteConfig } from "vue-router";
import Home from "../views/Home.vue";
import User from "../views/User.vue";
import SerialPage from "../views/SerialPage.vue";
import Stats from "../views/Stats.vue";
import store from "@/store";

Vue.use(VueRouter);

const routes: Array<RouteConfig> = [
  {
    path: "/",
    name: "Home",
    component: Home
  },
  {
    path: "/login",
    name: "Login"
  },
  {
    path: "/about",
    name: "About",
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () =>
      import(/* webpackChunkName: "about" */ "../views/About.vue")
  },
  {
    path: "/manage",
    name: "Manage",
    component: () =>
      import(/* webpackChunkName: "manage" */ "../views/Manage.vue"),
    meta: {
      requiresAdmin: true
    }
  },
  {
    path: "/request",
    name: "Request",
    component: () =>
      import(/* webpackChunkName: "request" */ "../views/Request.vue"),
    meta: {
      requiresAuth: true
    }
  },
  {
    path: "/users",
    name: "User",
    component: User,
    children: [{ path: "/users/:userId" }]
  },
  {
    path: "/serials",
    name: "Serial",
    component: SerialPage,
    children: [{ path: "/serials/:serial" }]
  },
  {
    path: "/stats",
    name: "Stats",
    component: () =>
      import(/* webpackChunkName: "Stats" */ "../views/Stats.vue"),
    meta: {
      requiresAdmin: true
    }
  },
  {
    path: "*",
    redirect: "/"
  }
];

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes
});

router.beforeEach((to, from, next) => {
  if (to.matched.some(record => record.meta.requiresAdmin)) {
    if (store.getters["authentication/isAdmin"]) {
      next();
      return;
    } else {
      /* return to previous path */
      next(from.fullPath);
    }
  } else if (to.matched.some(record => record.meta.requiresAuth)) {
    if (store.getters["authentication/isAuthenticated"]) {
      next();
      return;
    }
    next("/");
  } else {
    next();
  }
});

export default router;
