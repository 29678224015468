






























































import { Component, Prop, Watch, Vue } from "vue-property-decorator";
import { BSpinner, BAspect, BButton } from "bootstrap-vue";

@Component({ components: { BSpinner, BAspect, BButton } })
export default class SerialEmbed extends Vue {
  @Prop({ type: String, required: true }) readonly src!: string;
  @Prop({ type: Boolean, default: false }) readonly show!: boolean;

  private noEmbedPrefix = "NOEMBED//";
  private redditPrefix = "REDDITEMBED//";
  private redditMediaEmbed = "https://old.reddit.com/mediaembed/";
  private showRedditEmbed = this.show;
  private debounceTimeout?: number;
  private srcNoPrefix = this.src;
  private reddit?: {
    id: string;
    media: { id: string; reddit_video: { width: number; height: number } };
  };

  private get redditEmbedAspect(): string {
    const video = this.reddit?.media.reddit_video;
    return video && video.width > video.height
      ? video.width + ":" + video.height
      : "1:1";
  }

  private debounceShowRedditEmbed() {
    this.debounceTimeout = setTimeout(() => {
      this.showRedditEmbed = true;
    }, 500);
  }

  private cancelShowRedditEmbed() {
    clearInterval(this.debounceTimeout);
  }

  @Watch("src", { immediate: true })
  private async srcChanged(newValue: string) {
    if (newValue.startsWith(this.redditPrefix)) {
      this.srcNoPrefix = newValue.replace(this.redditPrefix, "");
      const postId = /REDDITEMBED\/\/https:\/\/www\.reddit\.com\/r\/(?<subreddit>\w+)\/comments\/(?<post_id>\w{7})\/.*/.exec(
        newValue
      )?.groups?.post_id;
      this.reddit = await new Promise((resolve, reject) => {
        const request = new XMLHttpRequest();
        request.open(
          "GET",
          `https://www.reddit.com/comments/${postId}.json?limit=1&app=embed`,
          true
        );
        request.setRequestHeader("Accept", "application/json");
        request.onreadystatechange = function() {
          4 === request.readyState &&
            (200 === request.status
              ? resolve(
                  JSON.parse(request.responseText)?.[0]?.data?.children?.[0]
                    ?.data
                )
              : reject("error"));
        };
        request.send();
      });
    } else {
      this.srcNoPrefix = newValue.replace(this.noEmbedPrefix, "");
      this.reddit = undefined;
    }
  }
}
