import Vue from "vue";
import VueHead from "vue-head";
import "bootstrap/dist/css/bootstrap.css";
import "bootstrap-vue/dist/bootstrap-vue.css";
import axios from "axios";
import VueGtag from "vue-gtag";

import App from "@/App.vue";
import router from "@/router";
import store from "@/store";

Vue.config.productionTip = false;

axios.defaults.baseURL = process.env.NODE_ENV === "production" ? "" : "https://registry-dev.vorondesign.com/";

store.dispatch("authentication/watchExpired");

Vue.use(VueHead, {
  separator: "-",
  complement: "Voron Registry"
});

if (process.env.NODE_ENV === "production") {
  Vue.use(
    VueGtag,
    {
      config: { id: "UA-151669745-2" }
    },
    router
  );
}

new Vue({
  router,
  store,
  render: h => h(App)
}).$mount("#app");
