


























































import { Component, Vue } from "vue-property-decorator";
import Login from "@/components/Login.vue";
import {
  BNavbar,
  BCollapse,
  BNavbarNav,
  BNavItem,
  BNavbarToggle,
  BNavbarBrand
} from "bootstrap-vue";

@Component({
  components: {
    Login,
    BNavbar,
    BCollapse,
    BNavbarNav,
    BNavItem,
    BNavbarToggle,
    BNavbarBrand
  }
})
export default class Header extends Vue {}
