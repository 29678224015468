















































































































































import { Prop, Component, Vue } from "vue-property-decorator";
import { Serial } from "@/types";
import * as timeago from "timeago.js";
import SerialEmbed from "@/components/SerialEmbed.vue";
import TransferSerial from "@/components/TransferSerial.vue";
import {
  BCollapse,
  BCard,
  BCardSubTitle,
  BCardText,
  BLink,
  BRow,
  BCol,
  BFormSelect,
  BInputGroup,
  BFormTextarea,
  BFormInput
} from "bootstrap-vue";

@Component({
  components: {
    SerialEmbed,
    TransferSerial,
    BCollapse,
    BCard,
    BCardSubTitle,
    BCardText,
    BLink,
    BRow,
    BCol,
    BFormSelect,
    BInputGroup,
    BFormTextarea,
    BFormInput
  }
})
export default class SerialCard extends Vue {
  @Prop({ type: Object, required: true }) readonly serial!: Serial;
  @Prop({ type: Boolean, default: false }) readonly collapse!: boolean;
  @Prop({ type: Boolean, default: true }) readonly show!: boolean;

  timeago = timeago;

  private editMode = false;
  private showTransferButton = false;
  private editedSerial: Serial | null = null;
  private revisionOptions = [
    "V0.0",
    "V0.1",
    "V1.0",
    "V1.5",
    "V1.6",
    "V1.8",
    "V2.0",
    "V2.1",
    "V2.2",
    "V2.4",
    "VS",
    "VL",
    "VT",
    "VPX"
  ];

  private get unescapedSerial(): Serial {
    return Object.fromEntries(
      Object.entries(this.serial).map(([k, v]) => [
        k,
        typeof v === "string" ? SerialCard.htmlDecode(v) : v
      ])
    ) as Serial;
  }

  private setEditMode(value: boolean): void {
    this.editMode = value;
  }

  private static htmlDecode(input: string): string | null {
    const doc = new DOMParser().parseFromString(input, "text/html");
    return doc.documentElement.textContent;
  }

  created() {
    this.showTransferButton =
      this.serial.userId === this.$store.getters["authentication/userId"] &&
      !!this.serial.userId?.startsWith("reddit");
    this.editedSerial = this.unescapedSerial;
  }
}
