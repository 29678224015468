import axios from "axios";
import { Serial, SerialRequest } from "@/types";
import { useAuth } from "@/services/auth.service";

/*
 * TODO:
 *  - "protect" protected resources
 *  - error handling
 *  - properly resolve promises
 *  - time-based serial fetching
 * */

const API_BASE = "/api";
const SERIALS_BASE = "/serials";

class RegistryService {
  batches: string[] | undefined;

  async listSerialBatches(): Promise<string[]> {
    const result = await axios.get<string[]>(SERIALS_BASE);
    return result.data;
  }

  async getSerialsBatch(
    timestamp = "latest"
  ): Promise<{ [key: string]: Serial }> {
    // replace serials/ path prefix if included
    timestamp = timestamp.replace("serials/", "");
    const result = await axios.get<{ [key: string]: Serial }>(
      `${SERIALS_BASE}/${timestamp}`
    );
    return result.data;
  }

  async getSerialByTimestamp(timestamp: number): Promise<Serial | undefined> {
    if (this.batches === undefined) {
      this.batches = await this.listSerialBatches();
    }

    const batchTimestamp = this.batches.find(
      element => timestamp <= Number(element.replace("serials/", ""))
    );

    const batch = await this.getSerialsBatch(batchTimestamp);
    return Object.values(batch).find(
      element => element.issued_time === timestamp
    );
  }

  async getUserSerials(userId: string): Promise<Serial[]> {
    const result = await axios.get<Serial[]>(
      `${API_BASE}/users/${userId}/serials`
    );
    return result.data;
  }

  async getSerial(serial: string): Promise<Serial> {
    const result = await axios.get<Serial>(`${API_BASE}/serials/${serial}`);
    return result.data;
  }

  @useAuth
  async exportDailyCSV(): Promise<any> {
    const result = await axios.get(`${API_BASE}/serials/export_daily_csv`, {
      responseType: 'blob'
    });
    return result
  }

  @useAuth
  async validateYoutubeId(youtubeId: string): Promise<boolean> {
    const result = await axios.get<boolean>(
      `${API_BASE}/requests/verify-youtube-id/${youtubeId}`
    );
    return result.data;
  }

  @useAuth
  async getUploadUrl(
    filename: string
  ): Promise<{ url: string; fields: Record<string, string> }> {
    const result = await axios.get<{
      url: string;
      fields: Record<string, string>;
    }>(`${API_BASE}/requests/upload-url?filename=${filename}`);
    return result.data;
  }

  @useAuth
  async postRequest(serial: SerialRequest): Promise<Serial> {
    const result = await axios.post<Serial>(`${API_BASE}/requests`, serial);
    return result.data;
  }

  @useAuth
  async getUserRequests(userId: string): Promise<Serial[]> {
    const result = await axios.get<Serial[]>(
      `${API_BASE}/users/${userId}/requests`
    );
    return result.data;
  }

  @useAuth
  async getOpenRequests(): Promise<Serial[]> {
    const result = await axios.get<Serial[]>(`${API_BASE}/requests?pending`);
    return result.data;
  }

  @useAuth
  async updateRequest(
    userId: string,
    requestId: string,
    serial: Serial
  ): Promise<Serial> {
    const result = await axios.patch<Serial>(
      `${API_BASE}/users/${userId}/requests/${requestId}`,
      serial
    );
    return result.data;
  }

  @useAuth
  async deleteRequest(userId: string, requestId: string): Promise<string> {
    const result = await axios.delete<string>(
      `${API_BASE}/users/${userId}/requests/${requestId}`
    );
    return result.data;
  }

  async getSerialCounters(): Promise<Record<string, number>> {
    const result = await axios.get<Record<string, number>>(
      `${API_BASE}/serials/counters`
    );
    return result.data;
  }

  @useAuth
  async setSerialCounter(
    model: string,
    newCounter: string | number,
    oldCounter: string | number
  ): Promise<Record<string, number>> {
    const result = await axios.put<Record<string, number>>(
      `${API_BASE}/serials/counters/${model}`,
      { newcounter: newCounter, oldcounter: oldCounter }
    );
    return result.data;
  }

  @useAuth
  async importRedditRequest(
    redditPost: string,
  ): Promise<Record<string, number>> {
    const result = await axios.post<Record<string, number>>(
      `${API_BASE}/requests/import`,
      { redditPost }
    );
    return result.data;
  }

  @useAuth
  async duplicateRequest(
    userId: string,
    requestId: string,
  ): Promise<Serial> {
    const result = await axios.post<Serial>(
      `${API_BASE}/users/${userId}/requests/${requestId}/duplicate`
    );
    return result.data;
  }

  @useAuth
  async adminTransfer(
    userId: string,
    requestId: string,
    serialNumber: string,
    serial: Serial
  ): Promise<Serial> {
    const result = await axios.post<Serial>(
      `${API_BASE}/users/${userId}/requests/${requestId}/transfer/${serialNumber}`,
      serial
    );
    return result.data;
  }

  @useAuth
  async transferSerial(
    userId: string,
    requestId: string,
    serial: Serial | Record<"transfer", string>
  ): Promise<string> {
    const result = await axios.patch<string>(
      `${API_BASE}/users/${userId}/serials/${requestId}`,
      serial
    );
    return result.data;
  }
}

export default new RegistryService();
