







import { Component, Vue } from "vue-property-decorator";
import { Serial } from "@/types";
import SerialCard from "@/components/SerialCard.vue";
import registryService from "@/services/registry.service";

@Component({
  head: {
    title: function() {
      return { inner: this.$route.params.serial };
    }
  },
  components: {
    SerialCard
  }
})
export default class SerialPage extends Vue {
  private serial: Serial | null = null;
  private serialNotFound = false;

  private async loadSerial() {
    this.serial = await registryService
      .getSerial(this.$route.params.serial)
      .catch(error => {
        this.serialNotFound = error.response.status === 404;
        return null;
      });
    console.log("serial:", this.serial);
  }

  created() {
    this.loadSerial();
  }
}
