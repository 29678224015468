


























import { Component, Prop, Vue } from "vue-property-decorator";
import { Serial } from "@/types";
import discordSvg from "../assets/discord-button.svg";
import registryService from "@/services/registry.service";
import { BSpinner } from "bootstrap-vue";

@Component({ components: { BSpinner } })
export default class TransferSerial extends Vue {
  @Prop({ type: Object, required: true }) readonly serial!: Serial;

  private discordSvg = discordSvg;
  private busy = false;
  private editing = false;
  private error?: string;
  private success = false;

  private handleTransfer() {
    this.error = undefined;
    this.editing ? this.finishTransfer() : this.startTransfer();
  }

  private async startTransfer() {
    this.busy = true;
    const oldJWT = this.$store.getters["authentication/jwt"];
    const newJWT = await this.$store.dispatch(
      "authentication/handleLogin",
      "discord"
    );
    const transfer = {
      transfer: this.$store.getters["authentication/userId"]
    };

    this.$store.commit("authentication/setJwtAndAuth", oldJWT);
    const initTransferRes = await registryService
      .transferSerial(this.serial.userId, String(this.serial.created), transfer)
      .catch(e => {
        this.error =
          "Error: " + (e.response.data.errorMessage || e.response.data.message);
      });
    console.log("Initialize transfer", initTransferRes);

    this.$store.commit("authentication/setJwtAndAuth", newJWT);
    this.busy = false;
    this.$emit("editSerial", true);
    this.editing = true;
  }

  private async finishTransfer() {
    this.busy = true;
    this.$emit("editSerial", false);
    this.editing = false;

    //this.serial.embed = undefined; //TODO
    this.serial.transfer = this.$store.getters["authentication/userId"];
    const finTransferRes = await registryService
      .transferSerial(
        this.serial.userId,
        String(this.serial.created),
        this.serial
      )
      .catch(e => {
        this.error =
          "Error: " + (e.response.data.errorMessage || e.response.data.message);
        this.busy = false;
        this.$emit("editSerial", true);
        this.editing = true;
      });
    console.log("Finish transfer", finTransferRes);

    this.success = !this.error;
  }
}
