import axios from "axios";
import store from "@/store";

const API_BASE = "/api";

//requestInterceptor for useAuth
let requestInterceptor: number;

async function authorize(
  provider: string,
  authCode: string,
  redirectUri: string
): Promise<string> {
  const params = new URLSearchParams({
    code: authCode,
    // eslint-disable-next-line @typescript-eslint/camelcase
    redirect_uri: redirectUri
  });
  const response = await axios.post(
    `${API_BASE}/authorize/${provider}`, //TODO
    params
  );
  if (response.status == 200) {
    return response.data;
  } else {
    //TODO: show error message
    console.error("authentication failed");
    return "";
  }
}

// decorator for use in front of service functions that need login
export function useAuth(
  target: object,
  propertyKey: string,
  descriptor: PropertyDescriptor
): PropertyDescriptor {
  const originalMethod = descriptor.value;

  descriptor.value = async function(...args: []) {
    // make sure interceptor from another function isn't in use
    axios.interceptors.request.eject(requestInterceptor);

    // set up interceptor
    requestInterceptor = axios.interceptors.request.use(async config => {
      // if jwt is set but expired and request isn't auth request: reauthenticate and set Authorization header
      if (
        store.getters["authentication/jwt"] &&
        store.getters["authentication/isExpired"] &&
        !config.url?.startsWith(`${API_BASE}/authorize`)
      ) {
        console.log("Login expired. Reauthenticating...");
        config.headers.Authorization = await store.dispatch(
          "authentication/handleLogin"
        );
      } else {
        config.headers.Authorization = store.getters["authentication/jwt"];
      }
      return config;
    });

    // execute original function
    const result = await originalMethod.apply(this, args);

    // eject interceptor and return original function result
    axios.interceptors.request.eject(requestInterceptor);
    return result;
  };

  return descriptor;
}

export const authService = {
  authorize,
  useAuth
};

export default authService;
