import axios from "axios";

import router from "@/router";
import { Module, GetterTree, MutationTree, ActionTree } from "vuex";
import authService from "@/services/auth.service";
import config from "../config";
import { RootState } from "./index";

const oauth = config.oauth;

type Json = string | number | boolean | null | Json[] | { [key: string]: Json };

export interface AuthState {
  jwt: string | null;
  auth: { admin: boolean; sub: string | null; [key: string]: Json };
  provider: keyof typeof oauth | undefined;
  expired: boolean;
  interval: number | undefined;
}

const state: AuthState = {
  jwt: null,
  auth: { admin: false, sub: null },
  provider: undefined,
  expired: false,
  interval: undefined
};

const getters: GetterTree<AuthState, RootState> = {
  isExpired: (state: AuthState): boolean => state.expired,
  isAuthenticated: (state: AuthState): boolean => !!state.jwt && !state.expired,
  isAdmin: (
    state: AuthState,
    getters: GetterTree<AuthState, RootState>
  ): boolean => !!getters.isAuthenticated && state.auth.admin,
  jwt: (state: AuthState): string | null => state.jwt,
  userId: (state: AuthState): string | null => state.auth.sub
};

const mutations: MutationTree<AuthState> = {
  setJwtAndAuth(state: AuthState, jwt: string) {
    state.jwt = jwt;
    //if jwt is empty store empty object
    state.auth = jwt?.length > 0 ? JSON.parse(atob(jwt.split(".")[1])) : {};
  },
  checkIfExpired(state: AuthState) {
    state.expired =
      !!state.auth.exp &&
      state.auth.exp < Math.round(new Date().getTime() / 1000);
  },
  setInterval(state: AuthState, interval: number) {
    state.interval = interval;
  }
};

const actions: ActionTree<AuthState, RootState> = {
  watchExpired({ state, commit }) {
    //start checking if auth expired every second
    clearInterval(state.interval);
    const interval = setInterval(() => {
      commit("checkIfExpired");
      if (state.expired || !state.jwt) {
        clearInterval(state.interval);
      }
    }, 1000);
    commit("setInterval", interval);
  },
  async handleLogin(
    { state, commit, dispatch },
    providerName?: keyof typeof oauth
  ) {
    if (providerName) state.provider = providerName;
    else providerName = state.provider;

    if (!providerName) return;

    const providerConfig = oauth[providerName];

    const randomState = [...Array(48)]
      .map(() => ((Math.random() * 36) | 0).toString(36))
      .join("");

    const oauthState = btoa(randomState);

    const popupQuery = [
      `client_id=${providerConfig.client_id}`,
      `response_type=${providerConfig.response_type}`,
      `scope=${providerConfig.scope}`,
      `state=${oauthState}`,
      `redirect_uri=${window.location.origin}/login`,
      `prompt=${providerConfig.prompt}`
    ].join("&");
    const popupUrl = `${providerConfig.auth_url}?${popupQuery}`;
    const popupName = "voron-registry-login-popup";

    const popupSize: { [key: string]: number } = {};
    popupSize.width = providerConfig.width;
    popupSize.height = 800;
    popupSize.left =
      window.screenLeft + (window.innerWidth - popupSize.width) / 2;
    popupSize.top =
      window.screenTop + (window.innerHeight - popupSize.height) / 2;

    const popupFeatures = [
      `scrollbars=no`,
      `resizable=no`,
      `status=no`,
      `location=no`,
      `toolbar=no`,
      `menubar=no`,
      `width=${popupSize.width}`,
      `height=${popupSize.height}`,
      `left=${popupSize.left}`,
      `top=${popupSize.top}`
    ].join(",");

    const popup = window.open(popupUrl, popupName, popupFeatures);
    popup?.focus();

    return await new Promise((resolve, reject) => {
      let popupWatcher = 0;
      const handler = async (payload: { code: string; state: string }) => {
        clearInterval(popupWatcher);
        if (payload.code && payload.state && payload.state === oauthState) {
          const jwt = await authService
            .authorize(
              providerName as string,
              payload.code,
              `${window.location.origin}/login`
            )
            .catch(e => {
              console.error(e.response);
              if (
                e.response.data.errorMessage === "member not found in guild"
              ) {
                reject(e.response.data.errorMessage);
              } else {
                reject("Could not authenticate...");
              }
            });

          commit("setJwtAndAuth", jwt);
          dispatch("watchExpired");
          resolve(jwt);
        } else {
          console.error("state mismatch!!");
          reject("state mismatch!!");
          //TODO: show error message
        }
      };
      const storageListener = (event: StorageEvent) => {
        if (event.key === "loginMessage" && event.newValue) {
          // immediately remove Message if it is in storage
          event.storageArea?.removeItem("loginMessage");

          handler(JSON.parse(event.newValue));
          window.removeEventListener("storage", storageListener);
        }
      };
      const messageListener = (event: MessageEvent) => {
        if (event.source === popup && event.origin === window.location.origin) {
          handler(event.data);
          window.removeEventListener("message", messageListener);
        }
      };
      window.onstorage = storageListener;
      window.onmessage = messageListener;

      popupWatcher = setInterval(function() {
        if (popup?.closed) {
          clearInterval(popupWatcher);
          window.removeEventListener("message", messageListener);
          window.removeEventListener("storage", storageListener);
          reject("popup closed");
        }
      }, 200);
    }).catch(error => {
      return { error: error };
    });
  },
  loginPopupCallback() {
    if (window.opener) {
      window.opener.postMessage(router.currentRoute.query, window.origin);
      window.close();
    } else {
      window.localStorage.setItem(
        "loginMessage",
        JSON.stringify(router.currentRoute.query)
      );
      window.close();
    }
  },
  async logout({ commit }) {
    commit("setJwtAndAuth", null);
    delete axios.defaults.headers.common["Authorization"];
  }
};

export const authentication: Module<AuthState, RootState> = {
  namespaced: true,
  state,
  getters,
  mutations,
  actions
};
