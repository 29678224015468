


































































import { Component, Vue } from "vue-property-decorator";
import SerialCard from "@/components/SerialCard.vue";
import { Serial } from "@/types";
import registryService from "@/services/registry.service";
import InfiniteLoading from "vue-infinite-loading";
import { StateChanger } from "vue-infinite-loading/types";
import * as timeago from "timeago.js";
import { BContainer, BRow, BCol, BCard, BLink, VBTooltip } from "bootstrap-vue";

@Component({
  components: {
    SerialCard,
    InfiniteLoading,
    BContainer,
    BRow,
    BCol,
    BCard,
    BLink
  },
  directives: { "b-tooltip": VBTooltip },
  head: {
    title: function() {
      return { inner: this.$options.name };
    }
  }
})
export default class Home extends Vue {
  private timeago = timeago;
  private serials: Record<string, Serial> = {};
  private batches: string[] = [];
  private selectedSerialIndex = -1;

  private async loadSerials() {
    const serials = await registryService.getSerialsBatch();
    console.log("serials:", serials);
    this.serials = { ...this.serials, ...serials };
  }

  private async loadMore($state: StateChanger) {
    if (!this.batches.length) {
      await this.loadBatches();
    }

    const batchTimestamps = this.batches.map(s => Number(s.split("/")[1]));
    const maxTimestamp = Math.max(...batchTimestamps);
    const latestBatch = `serials/${maxTimestamp}`;
    this.batches = this.batches.filter(e => e !== latestBatch);

    const serialBatch = await registryService.getSerialsBatch(latestBatch);
    this.serials = { ...this.serials, ...serialBatch };

    // if batches is empty all batches have been laoded
    if (this.batches.length) {
      $state.loaded();
    } else {
      $state.complete();
    }
  }

  private async loadBatches() {
    this.batches = await registryService.listSerialBatches();
  }

  private async selectSerial(serialIndex: number) {
    this.selectedSerialIndex = serialIndex;
  }

  private get serialList(): Serial[] {
    return Object.entries(this.serials)
      .map(([key, value]) => {
        value.serial = key;
        return value;
      })
      .sort((a, b) => {
        return b.issued_time - a.issued_time;
      });
  }

  private get selectedSerialStyle(): { marginTop: string; transition: string } {
    return {
      marginTop: this.selectedSerialIndex * 38 + "px",
      transition: "margin-top 0.3s ease-in-out"
    };
  }

  private get breakpointIsXs(): boolean {
    return (
      (window.innerWidth ||
        document.documentElement.clientWidth ||
        document.body.clientWidth) < 768
    );
  }

  created() {
    this.loadSerials().then(() => {
      this.selectedSerialIndex = 0;
    });
    this.loadBatches();
  }
}
